@font-face {
  font-family: 'FilsonPro';
  src: url(https://storage.googleapis.com/futurefamily-fonts/FilsonProLight.eot);
  src:
    url(https://storage.googleapis.com/futurefamily-fonts/FilsonProLight.eot?#iefix)
      format('embedded-opentype'),
    url(https://storage.googleapis.com/futurefamily-fonts/FilsonProLight.woff2)
      format('woff2'),
    url(https://storage.googleapis.com/futurefamily-fonts/FilsonProLight.woff)
      format('woff'),
    url(https://storage.googleapis.com/futurefamily-fonts/FilsonProLight.ttf)
      format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FilsonPro';
  src: url(https://storage.googleapis.com/futurefamily-fonts/FilsonProBook.otf);
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FilsonPro';
  src: url(https://storage.googleapis.com/futurefamily-fonts/FilsonProRegular.eot);
  src:
    url(https://storage.googleapis.com/futurefamily-fonts/FilsonProRegular.eot?#iefix)
      format('embedded-opentype'),
    url(https://storage.googleapis.com/futurefamily-fonts/FilsonProRegular.woff2)
      format('woff2'),
    url(https://storage.googleapis.com/futurefamily-fonts/FilsonProRegular.woff)
      format('woff'),
    url(https://storage.googleapis.com/futurefamily-fonts/FilsonProRegular.ttf)
      format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FilsonPro';
  src: url(https://storage.googleapis.com/futurefamily-fonts/FilsonProBold.eot);
  src:
    url(https://storage.googleapis.com/futurefamily-fonts/FilsonProBold.eot?#iefix)
      format('embedded-opentype'),
    url(https://storage.googleapis.com/futurefamily-fonts/FilsonProBold.woff2)
      format('woff2'),
    url(https://storage.googleapis.com/futurefamily-fonts/FilsonProBold.woff)
      format('woff'),
    url(https://storage.googleapis.com/futurefamily-fonts/FilsonProBold.ttf)
      format('truetype');
  font-weight: 700;
  font-display: swap;
}

:root {
  --font-family-primary: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  --font-family-secondary: FilsonPro, Helvetica Neue, Helvetica, Arial,
    sans-serif;

  --font-ratio: 1.125;
  --font-size-base: 14px;

  --font-size-1: var(--font-size-base);
  --font-size-2: calc(var(--font-size-1) * var(--font-ratio));
  --font-size-3: calc(var(--font-size-2) * var(--font-ratio));
  --font-size-4: calc(var(--font-size-3) * var(--font-ratio));
  --font-size-5: calc(var(--font-size-4) * var(--font-ratio));
  --font-size-6: calc(var(--font-size-5) * var(--font-ratio));

  --font-size-small: calc(var(--font-size-base) / var(--font-ratio));

  --gray: #828282;
  --red: #ff627e;
  --teal: #1ebdba;
  --purple: #6472d3;
}

@media (min-width: 768px) {
  :root {
    --font-ratio: 1.2;
  }
}

html,
body {
  font-size: var(--font-size-base);
  height: 100%;
  margin: 0;
  background-color: #f5f6f7;
  font-family: var(--font-family-primary);
  color: #30444a;
  height: 100%;

  overflow-y: auto;
  overflow-x: hidden;
}

html {
  overflow: unset;
}

#root {
  height: 100%;
}

a[role='button'] {
  cursor: pointer;
}

/* fill color of social icons in the footer */
g.social-svg-icon {
  fill: #696f7b !important;
}

p,
span,
li {
  font-size: 1rem;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-inherit {
  font-family: inherit;
}

.grecaptcha-badge {
  display: none;
}
